/**
 * Created by vladislav on 14.02.2020
 */

var GridPlayerView = cc.Node.extend({
    ctor: function (round, playerId) {
        this._super();

        this.round = round;
        this.playerId = playerId;
        this.index = cleverapps.knockoutGame.rumble.rounds[this.round.id].players.indexOf(playerId);

        this.player = cleverapps.knockoutGame.rumble.players[playerId];

        var index = cleverapps.knockoutGame.rumble.rounds[this.round.id].players.indexOf(playerId);
        this.setPositionRound(cleverapps.styles.GridPlayerView.positions[this.round.id][index]);

        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.GridPlayerView;
        this.setContentSize(styles.width, styles.height);
        this.avatar = new cleverapps.UI.Avatar(this.player);
        this.addChild(this.avatar);
        this.avatar.setPositionRound(this.width / 2, this.height / 2);

        cleverapps.UI.fitToBox(this.avatar, {
            width: this.width,
            height: this.height
        });

        this.baseScaleX = this.scaleX;
        this.baseScaleY = this.scaleY;

        this.setCascadeColorEnabled(true);
        this.setCascadeOpacityEnabled(true);
        this.avatar.setCascadeColorEnabled(true);
        this.avatar.setCascadeOpacityEnabled(true);

        this.setLocalZOrder(1);

        if (this.round.id === 0) {
            this.name = new GridPlayerNameView(this.player.name, this.index);
            this.addChild(this.name);
        }

        if (this.round.id !== 0) {
            this.path = new GridPathView(this.round, this.index, this.playerId);
            this.addChild(this.path);
        }

        this.setPositionRound(cleverapps.styles.GridPlayerView.positions[this.round.id][this.index]);
    },

    createPendingPlaceholder: function () {
        var pending = new cleverapps.Spine(bundles.battle.jsons.loading_json);
        pending.setPositionRound(this.getPosition());
        pending.setAnimation(0, "animation", true);

        this.pending = pending;
        this.parent.addChild(pending);
    },

    removePendingPlaceholder: function () {
        if (this.pending) {
            this.pending.removeFromParent();
            this.pending = undefined;
        }
    },

    crossOut: function (silent) {
        if (bundles.battle.jsons.cross_json) {
            var cross = this.cross = new cleverapps.Spine(bundles.battle.jsons.cross_json);
            cross.setAnimation(0, silent ? "idle" : "start", false);
            this.addChild(cross);
            cross.setPositionRound(this.width / 2, this.height / 2);
            cross.setCascadeColorEnabled(false);
            cross.setCascadeOpacityEnabled(false);
        }
    },

    runStartAnimation: function (delay, callback) {
        var onFinish = function () {
            this.removePendingPlaceholder();
            cleverapps.audio.playSound(bundles.main.urls.tournament_avatar_appearing_effect);
            callback();
        }.bind(this);

        this.createPendingPlaceholder();

        this.setVisible(false);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.Show(),
            new cc.CallFunc(onFinish)
        ));
    },

    runWinAnimation: function (callback, silent, delay) {
        var onFinish = function () {
            this.removePendingPlaceholder();
            callback();
        }.bind(this);

        this.createPendingPlaceholder();

        if (silent) {
            this.setWon();
            onFinish();
        } else {
            var styles = cleverapps.styles.GridPlayerView;

            var prevIndex = cleverapps.knockoutGame.rumble.rounds[this.round.id - 1].players.indexOf(this.playerId);

            var prevPos = styles.positions[this.round.id - 1][prevIndex];
            var currPos = styles.positions[this.round.id][this.index];
            var dist = cc.p(currPos.x - prevPos.x, currPos.y - prevPos.y);

            var start = cc.p(this.width / 2 - dist.x, this.height / 2 - dist.y);
            var finish = cc.p(this.width / 2, this.height / 2);
            var path = [start];
            if (this.round.id !== 3) {
                path.push(cc.p(start.x, start.y + dist.y / 2));
                path.push(cc.p(finish.x, start.y + dist.y / 2));
                path.push(cc.p(finish.x, finish.y));
            } else {
                path.push(cc.p(start.x, finish.y));
                path.push(cc.p(finish.x, finish.y));
            }

            var speed = 0.005 / resolutionScale;

            this.avatar.setPositionRound(path[0]);

            var actions = path.slice(1).map(function (pos, index) {
                var distX = pos.x - path[index].x;
                var distY = pos.y - path[index].y;
                var dist = Math.sqrt(distX * distX + distY * distY);
                return new cc.MoveTo(dist * speed, pos);
            });
            var MoveAction = cc.Sequence.bind.apply(cc.Sequence, [null].concat(actions));

            this.avatar.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    cleverapps.audio.playSound(bundles.main.urls.tournament_avatar_slide_effect);
                    this.path.animate();
                }.bind(this)),
                new MoveAction(),
                new cc.CallFunc(onFinish)
            ));
        }
    },

    runLoseAnimation: function (silent, delay) {
        if (this.cross) {
            this.cross.removeFromParent();
            this.cross = undefined;
        }

        this.setColor(new cc.Color(255, 255, 255, 255));
        if (silent) {
            this.setLost();
        } else {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.TintTo(0.5, 120, 120, 120),
                new cc.CallFunc(function () {
                    this.crossOut(silent);
                }.bind(this))
            ));
        }
    },

    setWon: function () {
        if (this.path) {
            this.path.animate(true);
        }
    },

    setLost: function () {
        this.setColor(new cc.Color(120, 120, 120, 255));

        if (this.name) {
            this.name.setLost();
        }

        if (this.path) {
            this.path.animate(true);
        }

        this.crossOut(true);
    },

    fadeOut: function () {
        if (this.path) {
            this.path.fadeOut();
        }

        if (this.name) {
            this.name.fadeOut();
        }

        this.avatar.runAction(new cc.FadeOut(0.5));

        if (this.cross) {
            this.cross.runAction(new cc.Sequence(
                new cc.DelayTime(0.25),
                new cc.Hide()
            ));
        }
    }
});

cleverapps.styles.GridPlayerView = {
    width: 120,
    height: 120,
    start: {
        dy: 200,
        dyOut: 40
    },

    positions: [
        [
            {
                x: 60, y: 934
            },
            {
                x: 222, y: 934
            },
            {
                x: 380, y: 934
            },
            {
                x: 542, y: 934
            },
            {
                x: 57, y: 136
            },
            {
                x: 220, y: 136
            },
            {
                x: 380, y: 136
            },
            {
                x: 542, y: 136
            }
        ],
        [
            {
                x: 137, y: 775
            },
            {
                x: 460, y: 775
            },
            {
                x: 137, y: 296
            },
            {
                x: 461, y: 296
            }
        ],
        [
            {
                x: 264, y: 615
            },
            {
                x: 264, y: 453
            }
        ],
        [
            {
                x: 474, y: 535
            }
        ]
    ]
};